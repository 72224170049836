<template>
    <div class="page-table scrollable haberTanim mainDiv listPage mb-30" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.slider.liste.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" type="text" style="margin-left: 15px !important" icon="el-icon-refresh" class="routeButton"></el-button>
                </el-tooltip>

                <el-tooltip :content='$t("src.views.apps.genel.yeniKaydet")' :open-delay="500" placement="bottom">
                    <el-button v-on:click="routeSlider('Slider Tanımla')" type="text" style="margin-left: 15px !important" icon="el-icon-plus"></el-button>
                </el-tooltip>
            </div>
        </div>

        <div class="page-header card-base card-shadow--medium animated fadeInUp pt-20" v-loading="loading || stateLoading"
            :element-loading-text='loading ? $t("src.views.apps.slider.liste.listLoading") : $t("src.views.apps.slider.liste.stateLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-row>
                <el-col :lg="16" :md="16" :sm="24" :xs="24">
                    <div>
                        <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray">
                            <el-radio-button label="1">
                                <i class="mdi mdi-image-filter-none mdi-24px" style="color: #13ce66; margin-right: 5px"></i>
                                <transition name="slide-fade">
                                    <label v-if="selectIcon==='1'">{{$t("src.views.apps.slider.liste.yayinda")}}</label>
                                </transition>
                            </el-radio-button>
                            <el-radio-button label="0">
                                <i class="mdi mdi-image-filter-none mdi-24px" style="color: #ec205f; margin-right: 5px"></i>
                                <transition name="slide-fade">
                                    <label v-if="selectIcon==='0'">{{$t("src.views.apps.slider.liste.pasif")}}</label>
                                </transition>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </el-col>
            </el-row>

            <el-row style="overflow: hidden" class="mt-20" :gutter="20">
                <el-col :lg="4" :md="4" :sm="24" :xs="24" v-for="(element,index) in sliderList" :key="element.sliderID" :data-gecerliindex="index">
                    <div class="sliderListeCerceve">
                        <div class="sliderListeButonAlani">
                            <el-tooltip class="item" effect="dark" content="Düzenle" placement="top">
                                <button  v-on:click="routeSlider('Slider Güncelle',element)"><i class="mdi mdi-pen"></i></button>
                            </el-tooltip>
                            <el-tooltip v-if="selectIcon === '1'" class="item" effect="dark" :content='$t("src.views.apps.slider.liste.kaldir")' placement="top">
                                <button @click="sliderDurumDegis(element,index,sliderList,'0')"><i class="mdi mdi-trash-can"></i></button>
                            </el-tooltip>
                            <el-tooltip v-if="selectIcon === '0'" class="item" effect="dark" :content='$t("src.views.apps.slider.liste.yayinla")' placement="top">
                                <button @click="sliderDurumDegis(element,index,sliderList,'1')"><i class="mdi mdi-play"></i></button>
                            </el-tooltip>
                        </div>

                        <img v-if="element.resim !== '0'" :src="imagepath+element.resim" alt="" class="img-fluid"/>
                        <img v-else src="../../../../public/static/images/gallery/default.png" alt="" class="img-fluid"/>
                        <div class="text-center">
                            {{element.baslik}}
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>

</template>

<script>
    import sliderService from '../../../WSProvider/SliderService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import draggable from "vuedraggable";

    export default {
        name: "SliderListesi",
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        components: {
            draggable
        },
        data() {
            return {
                stateLoading: false,
                loading :false,
                drag: false,
                selectIcon: '1',
                radio: '1',
                imagepath: sliderService.imagePath,
                sliderList: [],
            }
        },
        mounted() {
            let self = this;
            this.getSliderCount();

            EventBus.$on('sliderList', function (bool) {
                if (bool) {
                    self.getSliderCount();
                }
            });
        },
        methods: {
            refreshPage(){
                this.getSliderCount();
            },
            handleChange(event) {
                this.selectIcon = event;
                this.getSliderCount();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },
            getSliderList() {
                try{
                    this.loading = true;
                    sliderService.sliderList(this.selectIcon, 0, this.total).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token)
                            response.data.forEach(el => {
                                el = functions.removeSlashesAndDecodeStrings(el)
                            })
                            this.sliderList = response.data;
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.total = 0
                                this.sliderList = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            //notification.Status(503, this);
                        }
                        this.loading = false;
                    });
                }catch(e){
                    this.loading  = false
                }
            },
            getSliderCount() {
                try{
                    this.loading = true;
                    sliderService.sliderSayisi(this.selectIcon).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token)
                            this.total = response.data;
                            this.getSliderList();
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.total = 0
                                this.sliderList = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.loading = false;
                    });
                }catch(e){
                    this.loading = false;
                }
            },
            sliderDurumDegis(selection, index, list, durum) {
                this.openConfirmDialog("Slider'ın durumunu güncellemek istediğinize emin misiniz?").then(() => {
                    try{
                        this.stateLoading = true;
                        sliderService.sliderDurumGuncelle(selection.sliderID, durum).then(response => {
                            if(response.status == 200){
                                list.splice(index, 1);
                                localStorage.setItem("userDataDemirag", response.token)
                            }
                            notification.Status("success", this, response.msg);
                            this.stateLoading = false;    
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.stateLoading = false;
                        });    
                    }catch(e){
                        this.stateLoading = false;
                    }  
                })      
            },
            routeSlider(name, scope) {
                if (scope) {
                    this.$store.commit('changeSliderData', scope.sliderID);
                }
                this.routeSayfa(name)
            }
        }
    }

</script>


<style scoped lang="scss">
    .img-fluid {
        height: auto;
        max-width: 100%;
    }


    .sliderListeCerceve {
        box-shadow: 0 8px 16px 0 rgba(40, 40, 90, .09), 0 3px 6px 0 rgba(0, 0, 0, .065);
        padding: 5px;
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        transition: all .5s;

        .sliderListeButonAlani {
            position: absolute;
            height: calc(100% - 10px);
            width: calc(100% - 10px);
            left: 5px;
            top: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 300ms ease-in all;

            button {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                margin: 0 10px;
                background: #ffffff;
                color: #4b5970f0;
                line-height: 40px;
                font-size: 24px !important;
                outline: none !important;
                border: 1px solid #4b5970f0 !important;
                box-shadow: 0 8px 16px 0 rgba(40, 40, 90, .09), 0 3px 6px 0 rgba(0, 0, 0, .065);
                opacity: 0;
                transition: 200ms ease-in all;
                cursor: pointer;
            }
        }

        &:hover {
            box-shadow: none;
            transform: translateY(3px);

            .sliderListeButonAlani button {
                opacity: 1;
            }
        }
    }
</style>

